import React, { useState } from "react";
import "./journey.css";
import { HiOutlineAcademicCap, HiOutlineBriefcase, HiOutlineCalendar, HiAcademicCap, HiBriefcase } from "react-icons/hi";

const Journey = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <section className="journey section" id="journey">
            <h2 className="section__title">Journey</h2>
            <span className="section__subtitle">My path.</span>

            <div className="journey__container container">
                <div className="journey__tabs">
                    {/* Education Tab */}
                    <div
                        className={toggleState === 1 ? "journey__button button--flex journey__active" : "journey__button button--flex"}
                        onClick={() => toggleTab(1)}
                    >
                        {/* Toggle between filled and outline academic cap */}
                        {toggleState === 1 ? <HiAcademicCap className="journey__icon" /> : <HiOutlineAcademicCap className="journey__icon" />}
                        Education
                    </div>

                    {/* Experience Tab */}
                    <div
                        className={toggleState === 2 ? "journey__button button--flex journey__active" : "journey__button button--flex"}
                        onClick={() => toggleTab(2)}
                    >
                        {/* Toggle between filled and outline briefcase */}
                        {toggleState === 2 ? <HiBriefcase className="journey__icon" /> : <HiOutlineBriefcase className="journey__icon" />}
                        Experience
                    </div>
                </div>

                <div className="journey__sections">
                    {/* Education Content */}
                    <div className={toggleState === 1 ? "journey__content journey__content-active" : "journey__content"}>
                        <div className="journey__data">
                            <div>
                                <h3 className="journey__title">High School</h3>
                                <span className="journey__subtitle">Saint Francis</span>
                                <span className="journey__location">Mountain View, CA</span>
                                <div className="journey__calendar">
                                    <HiOutlineCalendar className="journey__calendar-icon" />
                                    2019 - 2023
                                </div>
                            </div>
                            <div>
                                <span className="journey__rounder"></span>
                                <span className="journey__line"></span>
                            </div>
                        </div>
                        <div className="journey__data">
                            <div></div>
                            <div>
                                <span className="journey__rounder"></span>
                                <span className="journey__line"></span>
                            </div>
                            <div>
                                <h3 className="journey__title">University</h3>
                                <span className="journey__subtitle">Purdue University</span>
                                <span className="journey__location">West Lafayette, IN</span>
                                <div className="journey__calendar">
                                    <HiOutlineCalendar className="journey__calendar-icon" />
                                    2023 - Current
                                </div>
                            </div>
                        </div>
                        <div className="journey__data">
                            <div>
                                <h3 className="journey__title">Certification</h3>
                                <span className="journey__subtitle">Machine Learning Specialization</span>
                                <span className="journey__location">Stanford</span>
                                <div className="journey__calendar">
                                    <HiOutlineCalendar className="journey__calendar-icon" />
                                    2024
                                </div>
                            </div>
                            <div>
                                <span className="journey__rounder"></span>
                                <span className="journey__line"></span>
                            </div>
                        </div>
                    </div>

                    {/* Experience Content */}
                    <div className={toggleState === 2 ? "journey__content journey__content-active" : "journey__content"}>
                        <div className="journey__data">
                            <div>
                                <h3 className="journey__title">SWE Intern</h3>
                                <span className="journey__subtitle">ThetaRho.ai</span>
                                <span className="journey__location">San Jose, CA</span>
                                <div className="journey__calendar">
                                    <HiOutlineCalendar className="journey__calendar-icon" />
                                    2024
                                </div>
                            </div>
                            <div>
                                <span className="journey__rounder"></span>
                                <span className="journey__line"></span>
                            </div>
                        </div>
                        <div className="journey__data">
                            <div></div>
                            <div>
                                <span className="journey__rounder"></span>
                                <span className="journey__line"></span>
                            </div>
                            <div>
                                <h3 className="journey__title">SWE Intern</h3>
                                <span className="journey__subtitle">IDWR Multi-Family Office</span>
                                <span className="journey__location">Remote</span>
                                <div className="journey__calendar">
                                    <HiOutlineCalendar className="journey__calendar-icon" />
                                    2024
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Journey;
