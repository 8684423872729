import React from "react";
import { HiCheckBadge } from "react-icons/hi2";

const Libraries = () => {
    return(
        <div className="skills__content">
        <h3 className="skills__title">&lt; Libraries &gt;</h3>
        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <HiCheckBadge />
                    <div>
                        <h3 className="skills__name">pandas</h3>
                        <span className="skills__level"></span>
                    </div>
                </div>
                <div className="skills__data">
                    <HiCheckBadge />
                    <div>
                        <h3 className="skills__name">NumPy</h3>
                        <span className="skills__level"></span>
                    </div>
                </div>
                <div className="skills__data">
                    <HiCheckBadge />
                    <div>
                        <h3 className="skills__name">matplotlib</h3>
                        <span className="skills__level"></span>
                    </div>
                </div>
                <div className="skills__data">
                    <HiCheckBadge />
                    <div>
                        <h3 className="skills__name">TensorFlow</h3>
                        <span className="skills__level"></span>
                    </div>
                </div>
            </div>
            <div className="skills__group">
                <div className="skills__data">
                    <HiCheckBadge />
                    <div>
                        <h3 className="skills__name">Selenium</h3>
                        <span className="skills__level"></span>
                    </div>
                </div>
                <div className="skills__data">
                    <HiCheckBadge />
                    <div>
                        <h3 className="skills__name">BeautifulSoup</h3>
                        <span className="skills__level"></span>
                    </div>
                </div>
                <div className="skills__data">
                    <HiCheckBadge />
                    <div>
                        <h3 className="skills__name">Jsoup</h3>
                        <span className="skills__level"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Libraries;